import React from "react";
import styles from "./Modal.module.css";

export default function Modal({ children,size }) {
  return (
    <div className={styles.modalWrapper}>
      <div className={`${styles.modalWrapper} ${styles[size]}`}>{children}</div>
    </div>
  );
}
