// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LjKA_X4Jn4vOGLUf4VIn {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}


.M7723L2MouOAjUlLdw9u {
  width: 98%;   
  height: 98%;  
}

.qujI2POomK1ZKZrvn9RA {
  width: 98%;   
  height: 98%;  
}

@media screen and (min-width: 768px) {
  .M7723L2MouOAjUlLdw9u {
    width: 50%;
    height: 50%;
  }

 .qujI2POomK1ZKZrvn9RA {
    width: 90%;
    height: 95%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Modal.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;;AAGA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE;IACE,UAAU;IACV,WAAW;EACb;;CAED;IACG,UAAU;IACV,WAAW;EACb;AACF","sourcesContent":[".modalWrapper {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n\n.modal-small {\n  width: 98%;   \n  height: 98%;  \n}\n\n.modal-large {\n  width: 98%;   \n  height: 98%;  \n}\n\n@media screen and (min-width: 768px) {\n  .modal-small {\n    width: 50%;\n    height: 50%;\n  }\n\n .modal-large {\n    width: 90%;\n    height: 95%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": `LjKA_X4Jn4vOGLUf4VIn`,
	"modal-small": `M7723L2MouOAjUlLdw9u`,
	"modal-large": `qujI2POomK1ZKZrvn9RA`
};
export default ___CSS_LOADER_EXPORT___;
